import { ERP_TEST } from '../../helpers/fakebackend_helper';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const GET_test = createAsyncThunk('test/get', async (_, thunkAPI) => {
  try {
    // Call the ERP_TEST function without any body data
    const response = await ERP_TEST();
    const data = await response;

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
