// src/components/TestDataComponent.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_test } from '../../../slices/Test/thunk';

const TestDataComponent = () => {
    const dispatch = useDispatch();

    // Access state from the Redux store
    const { data, loading, error } = useSelector((state) => state.Test);

    // Fetch data on component mount
    useEffect(() => {
        dispatch(GET_test());
    }, [dispatch]);
    console.log(data);
    return (
        <div>
            <h2>Test Data</h2>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {JSON.stringify(error)}</p>}
            {data && (
                <div>
                    <pre>{JSON.stringify(data)}</pre>
                </div>
            )}
        </div>
    );
};

export default TestDataComponent;
