import React from "react";
import { Navigate } from "react-router-dom";

//ERP Login
import Login from "../pages/ERPLogin/Authentication/Login";
import Logout from "../pages/ERPLogin/Authentication/Logout";

//Dashboards
import ERPDashboard from "../pages/Dashboards/ERPDashboard";

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: <Navigate to="/ERPLogin" />,
  },
  { path: "/Dashboards-ERP", component: <ERPDashboard /> },
  { path: "*", component: <Navigate to="/ERPLogin" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/ERPLogout", component: <Logout /> },
  { path: "/ERPLogin", component: <Login /> },
  //{ path: "/LicenseValidation", component: <LicenseValidation /> },
  //{ path: "/CompanySelection", component: <CompanySelection /> },
];

export { authProtectedRoutes, publicRoutes };
